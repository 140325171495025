<template>
  <Breadcrumb></Breadcrumb>
  <PageHeader
    title="Our Mission"
    backgroundImage="homepage/38675-004.jpg"
    backgroundColor="transparent"
    textColor="white"
    overlay="dark"
    align="center"
    size="lg"
  >
  </PageHeader>

  <Section type="text" class="">
    <p>The University of Georgia, a land-grant and sea-grant university with statewide commitments and responsibilities, is the state’s oldest, most comprehensive, and most diversified institution of higher education. Its motto, “to teach, to serve, and to inquire into the nature of things,” reflects the University’s integral and unique role in the conservation and enhancement of the state’s and nation’s intellectual, cultural, and environmental heritage.</p>
    
    <p>The University of Georgia shares with the other research universities of the University System of Georgia the following core characteristics:</p>
    
    <ul>
      <li>a statewide responsibility and commitment to excellence and academic achievements having national and international recognition;</li>
      <li>a commitment to excellence in a teaching/learning environment dedicated to serving a diverse and well-prepared student body, to promoting high levels of student achievement, and to providing appropriate academic support services;</li>
      <li>a commitment to excellence in research, scholarship, and creative endeavors that are focused on organized programs to create, maintain, and apply new knowledge and theories; that promote instructional quality and effectiveness; and that enhance institutionally relevant faculty qualifications;</li>
      <li>a commitment to excellence in public service, economic development, and technical assistance activities designed to address the strategic needs of the state of Georgia along with a comprehensive offering of continuing education designed to meet the needs of Georgia’s citizens in life-long learning and professional education;</li>
      <li>a wide range of academic and professional programming at the baccalaureate, master’s, and doctoral levels.</li>
    </ul>

    <p>With its statewide mission and core characteristics, the University of Georgia endeavors to prepare the University community and the state for full participation in the global society of the twenty-first century. Through its programs and practices, it seeks to foster the understanding of and respect for cultural differences necessary for an enlightened and educated citizenry. It further provides for cultural, ethnic, gender, and racial diversity in the faculty, staff, and student body. The University is committed to preparing the University community to appreciate the critical importance of a quality environment to an interdependent global society.</p>

    <p>As a comprehensive land-grant and sea-grant institution, the University of Georgia offers baccalaureate, master’s, doctoral, and professional degrees in the arts, humanities, social sciences, biological sciences, physical sciences, agricultural and environmental sciences, business, ecology, engineering, environmental design, family and consumer sciences, forest resources, journalism and mass communication, education, law, pharmacy, public health, social work, and veterinary medicine. The university is also home to the Augusta University/University of Georgia Medical Partnership.</p>

    <p>The University attracts students nationally and internationally as well as from within Georgia. It offers the state’s broadest array of possibilities in graduate and professional education, and thus a large minority of the student body is post-baccalaureate. The predominantly Georgian undergraduate student body is a mix of highly qualified students originally admitted as first-year students and selected transfer students principally from other University System institutions.</p>

    <p>With original scholarship, basic and applied research, and creative activities constituting an essential core from which to draw, the impact of the land-grant and sea-grant mission is reflected throughout the state. Cooperative extension, continuing education, public service, experiment stations, and technology transfer are all designed to enhance the well-being of the citizens of Georgia through their roles in economic, social, and community development.</p>

    <p>As it has been historically, the University of Georgia is responsive to the evolution of the state’s educational, social, and economic needs. It aspires through its strategic planning to even closer contact and interaction with public and private institutions throughout the state as well as with the citizens it serves.</p>

    <p><small>Update approved by the Board of Regents in April 2016.</small></p>
  </Section>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Section from '@/components/Section.vue';

export default {
  name: 'Our Mission',
  metaInfo: {
    title: 'The Mission of the University of Georgia',
    description: '',
    og: {
      image: '',
      title: '',
      description: '',
    },
  },
  data() {
    return {};
  },
  components: {
    PageHeader,
    Breadcrumb,
    Section,
  },
};
</script>

<style lang="scss" scoped></style>
